import { createRouter, createWebHistory } from 'vue-router'
//import App from "../App"
//import { createRouter } from 'vue-router'


const routes = [
  // {
  //   path: '/',
  //   name: 'App',
  //   component: App
  // },

  {
    path: '/pages/scanlogin',
    name: 'scanlogin',
    component: () => import('../components/ScanLogin')
  },
  {
    path: '/pages/mchat',
    name: 'mchat',
    component: () => import('../components/Mchat.vue')
  },
  {path: '/', redirect: '/pages/mchat'}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const router = createRouter({
//   mode: "hash",
//   routes
// })

export default router
